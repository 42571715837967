import React from "react";
import { SideBar } from "../components/SideBar/SideBar";


const MainPage = () => {
  return (
    <div>
      <SideBar />
    </div>
  );
};

export default MainPage;