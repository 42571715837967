import React from "react";
import {ProductsList} from "../components/ProductsList/ProductsList";


const ProductListPage = () => {
  return (
    <div>
      <ProductsList />
    </div>
  );
};

export default ProductListPage;